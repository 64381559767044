<template>
  <div id="app">
    <router-link to="/"></router-link>
    <keep-alive>
      <router-view
        :key="$route.name"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  created() {
    console.log(this.$route.meta.keepAlive, "created");
  },
  activated() {
    console.log(this.$route, "activated");
  },
};
</script>

<style>
body {
  margin: 0;
}
#app {
}
</style>
