
import { post } from '../axios/axios.js'//引入封装的文件中的post方法
import { get } from '../axios/axios.js'//引入封装的文件中的get方法
import axios from 'axios';
export default {
    //登录接口，params是接口所需参数，‘api/v2/auth’是接口地址中不同的后半部分
    //注：此接口是get请求接口，post请求，只需要将return get()换成return post()即可
    // 定位列表
 
    // 提交
    addSurvey(params){
        return post('index.php/survey/v1/addSurveyDataV1', params)
    },
    // 获取公司人员数量可选项 
    getTotalNumber(){
        return get('index.php/survey/v1/getTotalNumberCompanyPersonnel')
    },
    // 获取营销类人员数量可选项 
    getNumberMa(){
        return get('index.php/survey/v1/getNumberMarketingPersonnel')
    },
    //获取年龄段
    getAgeGroup(){
        return get('index.php/survey/v1/getAgeGroup')
    },
//  获取调研表是否停止收集
    getwhetherStopCollect(params){
        return get('index.php/survey/whetherStopCollect',params)

    },
    //获取二维码
    getQrcode(params){
        return get('index.php/survey/getQrcode',params)

    }
 
}